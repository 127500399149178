'use client';
/* eslint-disable @next/next/no-img-element */
import React from 'react';
import HeaderLinks from '../HeaderLinks';
import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const HeaderDC = () => {
  return (
    <header className="flex md:px-10 max-md:px-4 min-h-[88px] header">
      <div className="max-w-[960px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4">
        <Link href={ROUTES.home}>
          <img
            width="113"
            height="52"
            src="https://assets.davidcareers.com/davidcareers/cont_regular/logo_hr.png"
            alt="Logo"
          />
        </Link>
        <HeaderLinks
          listHeaderCn="px-3.5 py-2 text-dove-gray"
          dropdownClassNames={{
            listCn: 'max-w-[200px] right-0 bg-white py-3 custom-shadow-dc-2 rounded-2xl',
            elementCn: 'text-sm h-9 px-4 flex items-center hover:bg-gallery2',
            rootCn: '',
            iconCn: 'h-9 w-9 rounded-full hover:bg-white header-menu-icon-with-hover',
            activeIconCn: 'bg-white header-menu-icon',
          }}
          componentCn="flex gap-6 items-baseline"
          linkCn="uppercase text-main-text-color text-sm hover:translate-y-1"
        />
      </div>
    </header>
  );
};

export default HeaderDC;
