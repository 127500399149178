import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/dc/footer-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/dc/layout.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/dc/HeaderDC.tsx");
