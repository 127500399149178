'use client';

import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react';
import { cn } from '@/helpers/common';
import IconKebab from '@public/icons/kebab-icon.svg';

import Dropdown from './Dropdown';
import { ROUTES } from '@/constants/routes/default';
import { signOut } from 'next-auth/react';
import { TDropdownOptionElement } from '@/ts/types';

const HeaderDropdown = ({
  isAuthorized,
  introviewUrl,
  classNames,
  listHeader,
  isSighInPath,
  customOptions,
}: {
  isAuthorized: boolean;
  introviewUrl?: string;
  classNames: {
    iconCn?: string;
    activeIconCn?: string;
    rootCn?: string;
    listCn?: string;
    elementCn?: string;
    dividerCn?: string;
  };
  listHeader?: ReactNode;
  isSighInPath?: boolean;
  customOptions?: TDropdownOptionElement[];
}) => {
  const options = useMemo(() => {
    let result = [];

    if (customOptions && customOptions.length > 0) {
      return customOptions;
    }

    if (isAuthorized) {
      result = [
        { type: 'link', title: 'Search jobs', href: ROUTES.jobs, withDivider: true },
        {
          type: 'link',
          title: 'Career profile',
          href: ROUTES.profile,
          withDivider: false,
        },
        { type: 'button', title: 'Sign out', onClick: () => signOut(), withDivider: true },
      ];
    } else {
      result = [
        {
          type: 'link',
          title: 'Search jobs',
          href: ROUTES.jobs,
          withDivider: !!listHeader,
        },
        {
          type: 'link',
          title: isSighInPath ? 'Sign Up' : 'Sign In',
          href: ROUTES[isSighInPath ? 'signup' : 'signin'],
          withDivider: false,
        },
      ];
    }

    if (introviewUrl) {
      result.unshift({
        type: 'link',
        title: 'Post a job',
        href: introviewUrl,
        withDivider: false,
      });
    }

    return result;
  }, [isAuthorized, listHeader, introviewUrl, isSighInPath, customOptions]);

  return (
    <Dropdown
      rootCn={classNames?.rootCn}
      listCn={classNames?.listCn}
      elementCn={classNames?.elementCn}
      dividerCn={classNames?.dividerCn}
      listHeader={listHeader}
      options={options}
    >
      {({
        setIsOpen,
        isOpen,
      }: {
        setIsOpen: Dispatch<SetStateAction<boolean>>;
        isOpen: boolean;
      }) => (
        <button
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          className={cn(
            'flex items-center justify-center relative default-transition',
            classNames?.iconCn,
            isOpen ? classNames?.activeIconCn : '',
          )}
        >
          <IconKebab className="flex-shrink-0 [&>path]:transition-all [&>path]:duration-300" />
        </button>
      )}
    </Dropdown>
  );
};

export default HeaderDropdown;
