/* eslint-disable no-case-declarations */
import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { TGenericObject } from '@/ts/types';
import moment from 'moment';

export const filterEmptyProps = <T extends Record<string, any>>(obj: T): Partial<T> =>
  Object.keys(obj).reduce((result, key) => {
    const value = obj[key];
    if (!Number.isNaN(value) && value !== undefined && value !== null && value !== '') {
      (result as Record<string, any>)[key] = value; // Ensure result is writable
    }
    return result;
  }, {} as Partial<T>);

export const prefixWithQuestionMark = (input: string): string => {
  if (input.trim() === '') {
    return '';
  }
  return `?${input}`;
};

export const makeQueryString = (query: TGenericObject) =>
  prefixWithQuestionMark(new URLSearchParams(filterEmptyProps(query ?? {})).toString());

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const noop = () => {};

export const isWindow = (fn?: () => void) => {
  if (typeof window !== 'undefined') {
    fn?.();
    return true;
  }
  return false;
};

export const prolongEvent =
  <T,>(...callbacks: (((event: T) => void) | undefined)[]) =>
  (e: T) => {
    callbacks?.forEach((cb) => cb?.(e));

    return e;
  };

export const condition = <T, B>(condition: any, truthy: T, falsy: B) =>
  condition ? truthy : falsy;

export const numberToPx = (value: number) => (typeof value === 'number' ? `${value}px` : null);

export const formatDate = ({ dateValue, variant }: { dateValue?: Date; variant: number }) => {
  const date = dateValue ? new Date(dateValue) : new Date();

  switch (variant) {
    case 1:
      return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
    case 2:
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    case 3:
      let inputDate = moment(dateValue);
      if (inputDate < moment().subtract(30, 'days')) {
        inputDate = moment().subtract(30, 'days');
      }
      const isCurrentYear = moment().year() === inputDate.year();

      if (inputDate < moment().subtract(5, 'days')) {
        return inputDate.format(isCurrentYear ? 'MMM DD' : 'MMM DD, YYYY');
      }

      return inputDate.fromNow();
    default:
      return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date);
  }
};

export const onClickElement =
  <T extends HTMLElement>(cb?: (e: React.MouseEvent<T, MouseEvent>) => void) =>
  (event: React.MouseEvent<T, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      cb?.(event);
    }
  };

export const formatLocationCaseText = (text: string) => {
  let words = text.split(' ');
  words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  if (words.length > 1 && words[words.length - 2].endsWith(',')) {
    words[words.length - 1] = words[words.length - 1].toUpperCase();
  }

  return words.join(' ');
};
