import { Dispatch, Fragment, ReactNode, SetStateAction, useRef, useState } from 'react';

import { useClickOutside } from '@/hooks/useClickOutside';
import { cn } from '@/helpers/common';
import { useRouter } from 'next/navigation';
import { TDropdownOptionElement } from '@/ts/types';

interface IDropdownComponentProps {
  children: ({
    isOpen,
    setIsOpen,
  }: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  }) => ReactNode;
  options?: TDropdownOptionElement[];
  listCn?: string;
  elementCn?: string;
  activeElementCn?: string;
  rootCn?: string;
  dividerCn?: string;
  listHeader?: ReactNode;
  closeOnSelect?: boolean;
}

const Dropdown = ({
  children,
  listHeader,
  options,
  listCn,
  elementCn,
  activeElementCn,
  rootCn,
  dividerCn,
  closeOnSelect = true,
}: IDropdownComponentProps) => {
  const { push } = useRouter();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef as any, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className={cn('relative', rootCn)}>
      {children({ setIsOpen, isOpen })}
      {isOpen && (
        <ul
          className={cn(
            'absolute flex flex-col text-center overflow-hidden top-[calc(100%+5px)] min-w-20 z-50',
            listCn,
          )}
        >
          {listHeader}
          {options &&
            options.map((el, index) => (
              <Fragment key={el?.title + index}>
                {el?.withDivider && (
                  <div className={cn('my-1 border-t border-main-border-color', dividerCn)} />
                )}
                {el?.topChild}
                <li
                  className={cn(
                    'flex cursor-pointer whitespace-nowrap short-transition relative',
                    elementCn,
                    el.isActive ? activeElementCn : '',
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (closeOnSelect) setIsOpen(false);
                    if (el.type === 'button') {
                      el.onClick(el);
                    } else {
                      push(el?.href as string);
                    }
                  }}
                >
                  {el?.icon}
                  {el.titleBlock ?? el.title}
                </li>
              </Fragment>
            ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
