/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import { ROUTES } from '@/constants/routes/default';
import { cn } from '@/helpers/common';
import { signOut, useSession } from 'next-auth/react';
import HeaderDropdown from './Dropdowns/HeaderDropdown';
import { usePathname } from 'next/navigation';

const HeaderLinks = ({
  withCenteredItems,
  onlyDropdown = false,
  introviewUrl,
  searchIcon,
  componentCn,
  linkCn,
  listHeaderCn,
  dropdownClassNames,
  skipSession = false,
}: {
  withCenteredItems?: boolean;
  onlyDropdown?: boolean;
  introviewUrl?: string;
  linkCn: string;
  componentCn: string;
  listHeaderCn?: string;
  searchIcon?: React.ReactNode;
  dropdownClassNames: {
    iconCn?: string;
    activeIconCn?: string;
    rootCn?: string;
    listCn?: string;
    elementCn?: string;
    dividerCn?: string;
  };
  skipSession?: boolean;
}) => {
  const pathname = usePathname();
  const isSighInPath = pathname === ROUTES.signin;
  let session = null;
  if (!skipSession) {
    const { data: value } = useSession();
    session = value;
  }

  return (
    <>
      {withCenteredItems && (
        <div className={cn('md:flex max-md:hidden', componentCn)}>
          <a className={cn('no-underline default-transition', linkCn)} href={introviewUrl}>
            Post a job
          </a>
          <a className={cn('no-underline default-transition', linkCn)} href={ROUTES.jobs}>
            {searchIcon ? searchIcon : null}
            Search jobs
          </a>
        </div>
      )}
      <div>
        <div className="md:hidden max-md:block">
          <HeaderDropdown
            classNames={dropdownClassNames}
            isAuthorized={!!session}
            introviewUrl={introviewUrl}
            isSighInPath={isSighInPath}
            listHeader={
              session && session?.user?.email ? (
                <div
                  className={cn(
                    'text-xs overflow-hidden whitespace-nowrap text-ellipsis',
                    listHeaderCn,
                  )}
                >
                  {session?.user?.email}
                </div>
              ) : null
            }
          />
        </div>
        {!onlyDropdown && (
          <div className={cn('md:flex max-md:hidden', componentCn)}>
            {!withCenteredItems && (
              <a className={cn('no-underline default-transition', linkCn)} href={ROUTES.jobs}>
                {searchIcon ? searchIcon : null}
                Search jobs
              </a>
            )}
            {session ? (
              <>
                <a className={cn('no-underline default-transition', linkCn)} href={ROUTES.profile}>
                  Career profile
                </a>
                <div>|</div>
                <span>
                  <button
                    className={cn('no-underline default-transition', linkCn)}
                    onClick={() => signOut()}
                  >
                    Sign out
                  </button>
                </span>
              </>
            ) : (
              <>
                <a
                  className={cn('no-underline default-transition', linkCn)}
                  href={ROUTES[isSighInPath ? 'signup' : 'signin']}
                >
                  {isSighInPath ? 'Sign Up' : 'Sign In'}
                </a>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderLinks;
