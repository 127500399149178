'use client';

/* eslint-disable consistent-return */
import { RefObject, useEffect } from 'react';

export type RefType = RefObject<HTMLElement>;

interface IOptions {
  skip?: boolean;
}

export const useClickOutside = (ref: RefType, callback: (e: MouseEvent | TouchEvent ) => void, options?: IOptions) => {
  const { skip = false } = options ?? {};
  useEffect(() => {
    if (skip) return;
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [callback, ref, skip]);
};
